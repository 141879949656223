<template>
  <div>
    <h2>Booking Available Summary</h2>
    <div class="filters">
      <a-range-picker
        :ranges="filterDateRangePresets"
        format="YYYY/MM/DD"
        :defaultValue="[filters.dateStart, filters.dateEnd]"
        @change="handleDateRangeChange"
      />
    </div>
    <div class="map-chart">
      <chart
        ref="chart"
        style="width: 100%; height: 800px"
        :getChartOptions="getChartOptions"
        :getData="getData"
      />
    </div>
  </div>
</template>

<script>
import { queryBookingData } from '@/api/appointment'
import chart from '@/components/chart'
import moment from 'moment'
import { time, use } from 'echarts/core'
import { MarkPointComponent } from 'echarts/components'
import { BarChart } from 'echarts/charts'
use([BarChart, MarkPointComponent])

const getTimeGroupedAppointments = (appointments) => {
  // Free排在最后
  return appointments.sort((a, b) => b.status === 'Free' ? -1 : 0).reduce((m, a) => {
    let time = moment.utc(a.start_date)
    if (time.minute() !== 0 && time.minute() !== 30) {
      time.minute(Math.floor(time.minute() / 30) * 30)
    }
    time = time.valueOf()
    if (!m[time]) {
      m[time] = [a]
      return m
    }
    const pm = m[time].filter(t => t.practitioner_id === a.practitioner_id)
    if (!pm.length) {
      // 没有practitioner的appointment,直接加入
      m[time].push(a)
      return m
    }

    if (a.status === 'Free') {
      // Free遇到Cancel，踢掉Cancel保留Free
      if (pm.find(t => t.status === 'Canceled')) {
        m[time] = [...m[time].filter(t => t.practitioner_id !== a.practitioner_id), a]
      }
    } else if (a.status === 'Canceled') {
      // （这里有其他项存在）Cancel的直接忽略
    } else {
      // 如果是其他(Missed/Confirmed)状态，删除同一个时间段内Canceled
      if (pm.find(t => t.status === 'Canceled')) {
        m[time] = [...m[time].filter(t => t.practitioner_id !== a.practitioner_id), a]
      } else {
        m[time].push(a)
      }
    }
    return m
  }, {})
}

export default {
  components: { chart },
  data () {
    return {
      filterDateRangePresets: {
        'Today': [moment().startOf('day'), moment().endOf('day')],
        '3 Days': [moment().startOf('day'), moment().endOf('day').add(2, 'days')],
        'This Week': [moment().startOf('week'), moment().endOf('week')],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
      },
      filters: {
        dateStart: moment().startOf('day'),
        dateEnd: moment().endOf('day').add(2, 'days'),
      }
    }
  },
  methods: {
    async getData () {
      const { appointments, locations } = await queryBookingData(this.filters)
      const locationFreeMap = locations.reduce((m, l) => {
        m[l.id] = 0
        return m
      }, {})
      const timeGroupedAppointments = getTimeGroupedAppointments(appointments)
      Object.values(timeGroupedAppointments).flat().filter(a => a.status === 'Free').forEach(a => {
        locationFreeMap[a.location_id]++
      })
      locations.forEach(l => {
        l.free = locationFreeMap[l.id]
      })
      return locations
    },
    getChartOptions ({ data }) {
      data = (data || []).filter(t => t.free > 0).reverse()
      const categoryData = data.map(t => t.name)
      return {
        title: {
          text: ''
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          data: ['Free']
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          boundaryGap: [0, 0.01]
        },
        yAxis: {
          type: 'category',
          data: categoryData
        },
        series: [
          {
            name: 'Free',
            type: 'bar',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            data: data.map(t => t.free)
          },
        ]
      }
    },
    handleDateRangeChange ([dateStart, dateEnd]) {
      this.filters.dateStart = dateStart
      this.filters.dateEnd = dateEnd
      this.$refs.chart.updateData()
    }
  }
}
</script>

<style lang="scss">
.calendar {
  --fc-small-font-size: 0.7em;

  .fc-timegrid-event-harness-inset .fc-timegrid-event,
  .fc-timegrid-event.fc-event-mirror,
  .fc-timegrid-more-link {
    box-shadow: none;
    margin: 1px 1px 1px 0;
    cursor: pointer;
  }
}

.filters {
  > * {
    margin: 0.5em;
  }
}
</style>
